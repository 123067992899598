import React from 'react'
import Maze from './Components/Maze'
import Navigation from './Components/AppBar'
import TicTacToe from './Components/TicTacToe'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

function App() {
  return (
    <div>
      <Router>
        <Navigation />
        <Switch>
          <Route exact path='/' component={Maze}/>
          <Route exact path='/maze' component={Maze}/>
          <Route exact path='/tic-tac-toe' component={TicTacToe}/>
        </Switch>
      </Router>
    </div>
  )
}

export default App
