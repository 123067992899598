import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Grid, Container, Paper, Select, MenuItem, InputLabel, IconButton, FormControl, Button, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { green, red, yellow, grey, purple } from '@material-ui/core/colors'
import Start from '@material-ui/icons/LocationOn'
import Goal from '@material-ui/icons/EmojiEvents'
import Wall from '@material-ui/icons/ViewModule'
import Trash from '@material-ui/icons/Delete'
import Clear from '@material-ui/icons/Clear'
import Restart from '@material-ui/icons/Replay'

const GridType = {
    WALL: 0,
    OPEN: 1,
    GOAL: 3,
    START: 4,
    EXPLORED: 5,
    PATH: 6,
    DISCOVERED: 7,
}

class Node {
    constructor(state, steps) {
        this.state = state
        this.action = "None"
        this.parent = null
        this.steps = steps
    }
}

class Neighbor {
    constructor(action, row, col) {
        this.action = action
        this.row = row
        this.col = col
    }
}

class StackFrontier {
    constructor() {
        this.frontier = new Array()
    }

    add(node) {
        this.frontier.push(node)
    }

    containsState(state) {
        for (var i = 0; i < this.frontier.length; i++) {
            if (state.row === this.frontier[i].state.row && state.col === this.frontier[i].state.col) {
                return true
            }
        }
        return false
    }

    empty() {
        return this.frontier.length === 0
    }

    size() {
        return this.frontier.length
    }

    remove() {
        return this.frontier.pop()
    }
};

class QueueFrontier extends StackFrontier {
    remove() {
        return this.frontier.shift()
    }
};

class ManhattanFrontier extends StackFrontier {
    constructor(maze) {
        super()
        this.maze = maze
    }
    remove() {
        var lowestVal = 999999
        var bestChoice = null
        var indexToRemove = null
        for (var index = 0; index < this.frontier.length; index++) {
            let value = this.maze.manhattanGrid[this.frontier[index].state.row][this.frontier[index].state.col]
            if (value < lowestVal) {
                bestChoice = this.frontier[index]
                lowestVal = value
                indexToRemove = index
            }
        }
        this.frontier.splice(indexToRemove, 1)
        return bestChoice
    }
}

class AStarFrontier extends StackFrontier {
    constructor(maze) {
        super()
        this.maze = maze
    }
    remove() {
        var lowestVal = 999999
        var bestChoice = null
        var indexToRemove = null
        for (var index = 0; index < this.frontier.length; index++) {
            let steps = this.frontier[index].steps
            let toGoal = this.maze.manhattanGrid[this.frontier[index].state.row][this.frontier[index].state.col]
            let value = toGoal + steps
            if (value < lowestVal) {
                bestChoice = this.frontier[index]
                lowestVal = value
                indexToRemove = index
            }
        }
        this.frontier.splice(indexToRemove, 1)
        return bestChoice
    }
}

class Maze {
    constructor(grid, setGrid, algorithm) {
        this.grid = grid
        this.setGrid = setGrid
        this.explored = new Set()
        this.goal = { row: 0, col: 0 }
        this.start = null
        this.manhattanGrid = []
        this.algorithm = algorithm
        switch (algorithm) {
            case Algorithm.DFS:
                this.frontier = new StackFrontier()
                break
            case Algorithm.BFS:
                this.frontier = new QueueFrontier()
                break
            case Algorithm.MANHATTAN:
                this.frontier = new ManhattanFrontier(this)
                break
            case Algorithm.ASTAR:
                this.frontier = new AStarFrontier(this)
                break
        
            default:
                this.frontier = new StackFrontier()
                break
        }
    }
    // Finds the start and end of maze
    setUp() {
        
        // find START and GOAL
        for (var row = 0; row < this.grid.length; row++) {
            for (var col = 0; col < this.grid[row].length; col++) {
                switch (this.grid[row][col]) {
                    case GridType.START:
                        this.start = new Node({row, col}, 0)
                        this.frontier.add(this.start)
                        break
                    case GridType.GOAL:
                        this.goal = { row, col }
                        break
                
                    default:
                        break
                }
            }
        }

        if (this.algorithm === Algorithm.MANHATTAN || this.algorithm === Algorithm.ASTAR) {
            for (var row = 0; row < this.grid.length; row++) {
                this.manhattanGrid.push([])
                for (var col = 0; col < this.grid[row].length; col++) {
                    this.manhattanGrid[row].push(Math.abs(this.goal.col - col) + Math.abs(this.goal.row - row))
                }
            }
        }
    }

    // Returns a list of all possible neighbors to explore
    getNeighbors(node) {

        let row = node.state.row
        let col = node.state.col

        // get potential candidates
        let candidates = new Array()
        if (row > 0) {
            candidates.push(new Neighbor("up", row - 1, col))
        }
        if (row < this.grid.length - 1) {
			candidates.push( new Neighbor("down", row + 1, col) )
        }
        if (col > 0) {
			candidates.push( new Neighbor("left", row, col - 1) )
        }
        if (col < this.grid[row].length - 1) {
			candidates.push( new Neighbor("right", row, col + 1) )
        }
        
        // test candidates
        var neighbors = new Array()
        for (var i = 0; i < candidates.length; i++) {
            var n = candidates[i]
            let notBeenExplored = !this.hasBeenExplored( { row: n.row, col: n.col } )
            let notInFrontier = !this.frontier.containsState(n)
            let notAWall = (this.grid[n.row][n.col] !== 0)
            if (notBeenExplored && notInFrontier && notAWall) {
                neighbors.push(n)
            }
        }

        return neighbors
    }

    hasBeenExplored( val ) {
        for (let item of this.explored) {
            if (item.row === val.row && item.col === val.col) {
                return true
            }
        }
        return false
    }

    drawPath(node) {

        var nodePointer = node.parent
        while (nodePointer.parent !== null) {

            let copy = [...this.grid]
            copy[nodePointer.state.row][nodePointer.state.col] = GridType.PATH
            this.setGrid(copy)
            nodePointer = nodePointer.parent
        }
    }


    solve() {
            
            var time = Date.now()
            while (Date.now() - time < 100) {

            }

            // Check if frontier is empty
            if (this.frontier.empty()) {
                console.log('No solution')
                return -1
            }

            // remove a node from the frontier
            var node = this.frontier.remove()

            // Check if the node is in a goal state
            var location = { row: node.state.row, col: node.state.col }
            if (this.goal.row === location.row && this.goal.col === location.col) {
                var copy = [...this.grid]
                copy[location.row][location.col] = GridType.DISCOVERED
                this.setGrid(copy).then(() => {this.drawPath(node)})
                return 1
            }

            // Add the location to the explored set
            this.explored.add(location)

            // Color in the explored location on the maze
            var copy = [...this.grid]
            if (copy[location.row][location.col] !== GridType.START) {
                copy[location.row][location.col] = GridType.EXPLORED
                this.setGrid(copy).then(() => {this.expandAndExplore(node)}) 
            }
            else {
                this.expandAndExplore(node)
            }
    
    }

    expandAndExplore(node) {
        // expand the node and add the neighbors to the frontier
        const newNeighbors = this.getNeighbors(node)
        for (var i = 0; i < newNeighbors.length; i++) {
            var newNeighbor = newNeighbors[i]
            var newNode = new Node( { row: newNeighbor.row, col: newNeighbor.col }, node.steps + 1 )
            newNode.action = newNeighbor.action
            newNode.parent = node
            this.frontier.add(newNode)
        }
    
        // call solve again
        this.solve()
     }
 };

 const useStateWithPromise = (initialState) => {
    const [state, setState] = useState(initialState);
    const resolverRef = useRef(null);
  
    useEffect(() => {
      if (resolverRef.current) {
        resolverRef.current(state);
        resolverRef.current = null;
      }
      /**
       * Since a state update could be triggered with the exact same state again,
       * it's not enough to specify state as the only dependency of this useEffect.
       * That's why resolverRef.current is also a dependency, because it will guarantee,
       * that handleSetState was called in previous render
       */
    }, [resolverRef.current, state]);
  
    const handleSetState = useCallback((stateAction) => {
      setState(stateAction);
      return new Promise(resolve => {
        resolverRef.current = resolve;
      });
    }, [setState])
  
    return [state, handleSetState];
  };

const useStyles = makeStyles(theme => ({
    root: {
        paddingTop: 50,
    },
    fullSize: {
        height: '100%'
    },
    wall: {
        backgroundColor: grey[600],
    },
    goal: {
        backgroundColor: green[500],
    },
    goalBright: {
        backgroundColor: '#00e676',
    },
    start: {
        backgroundColor: red[500],
    },
    explored: {
        backgroundColor: yellow[500],
    },
    path: {
        backgroundColor: purple[500],
    }
}))

const Algorithm = {
    DFS: 1,
    BFS: 2,
    MANHATTAN: 3,
    ASTAR: 4,
}

function MazeGrid( { width, height, setWidth, setHeight } ) {
    const classes = useStyles()

    const [grid, setGrid] = useStateWithPromise([])
    const [size, setSize] = useState(0)
    const [wW, setWW] = useState(window.innerWidth)
    const [wH, setWH] = useState(window.innerHeight)
    const [placeGoal, setPlaceGoal] = useState(false)
    const [placeStart, setPlaceStart] = useState(false)
    const [remove, setRemove] = useState(false)
    const [algorithm, setAlgorithm] = useState(Algorithm.DFS)

    const handleClick = (e, row, col) => {
        e.preventDefault()
        var copy = [...grid]

        if (placeGoal) {
            if (noGoal()) {
                copy[row][col] = GridType.GOAL
                setPlaceGoal(false)
            }
            else {
                // show an alert
            }
        }
        else if (placeStart) {
            if (noStart()) {
                copy[row][col] = GridType.START
                setPlaceStart(false)
            }
            else {
                // show an alert
            }
        }
        else if (remove) {
            copy[row][col] = GridType.OPEN
        }
        else {
            copy[row][col] = GridType.WALL
        }

        setGrid(copy)
    }

    const noGoal = () => {
        for (var row = 0; row < grid.length; row++) {
            if (grid[row].includes(GridType.GOAL)) {
                return false
            }
        }
        return true
    }

    const noStart = () => {

        for (var row = 0; row < grid.length; row++) {
            if (grid[row].includes(GridType.START)) {
                return false
            }
        }
        return true
    }

    const handleStart = (e) => {
        e.preventDefault()
        setPlaceGoal(false)
        setPlaceStart(true)
        setRemove(false)
    }

    const handleGoal = (e) => {
        e.preventDefault()
        setPlaceStart(false)
        setPlaceGoal(true)
        setRemove(false)
    }

    const handleWall = (e) => {
        e.preventDefault()
        setPlaceStart(false)
        setPlaceGoal(false)
        setRemove(false)
    }

    const handleRemove = (e) => {
        e.preventDefault()
        setPlaceStart(false)
        setPlaceGoal(false)
        setRemove(true)
    }

    const handleWidth = (e) => {
        setWidth(e.target.value)
    }

    const handleHeight = (e) => {
        setHeight(e.target.value)
    }

    const handleAlgorithm = (e) => {
        setAlgorithm(e.target.value)
    }

    const handleSolve = (e) => {
        e.preventDefault()

        var maze = new Maze(grid, setGrid, algorithm)
        maze.setUp()
        maze.solve()
    }

    const handleReset = (e) => {
        e.preventDefault()
        var tempGrid = []
        for (var row = 0; row < height; row++ ) {
            tempGrid.push([])
            for (var col = 0; col < width; col++) {
                tempGrid[row].push(GridType.OPEN)
            }
        }
        setGrid(tempGrid)
    }

    const handleResetMaze = (e) => {
        e.preventDefault()
        var tempGrid = []
        for (var row = 0; row < height; row++ ) {
            tempGrid.push([])
            for (var col = 0; col < width; col++) {
                switch (grid[row][col]) {
                    case GridType.WALL:
                        tempGrid[row].push(GridType.WALL)
                        break
                    case GridType.START:
                        tempGrid[row].push(GridType.START)
                        break
                    case GridType.GOAL:
                        tempGrid[row].push(GridType.GOAL)
                        break
                    case GridType.DISCOVERED:
                        tempGrid[row].push(GridType.GOAL)
                        break
                    default:
                        tempGrid[row].push(GridType.OPEN)
                        break
                }
                
            }
        }
        setGrid(tempGrid)
    }

    useEffect(() => {
        var tempGrid = []
        for (var row = 0; row < height; row++ ) {
            tempGrid.push([])
            for (var col = 0; col < width; col++) {
                tempGrid[row].push(GridType.OPEN)
            }
        }
        setGrid(tempGrid)

        if (width / height >= wW / (wH - 150)) {
            setSize(wW / width)
        } else {
            setSize((wH - 150) / height)
        }
    }, [width, height, wW, wH])

    const squareSize = {
        width: size,
        height: size,
    }

    return (
        <div className={classes.root}>
            <Container maxWidth='md' >
                <Grid container direction='row' justify='center' alignItems='center' spacing={3}>
                    <Grid item>
                        <Grid container direction='row' justify='center' alignItems='center' spacing={2}>
                            <Grid item>
                                <FormControl>
                                    <InputLabel htmlFor="width">Width</InputLabel>
                                    <Select onChange={handleWidth} value={width} style={{width: 80}} id='width'>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={40}>40</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={60}>60</MenuItem>
                                        <MenuItem value={70}>70</MenuItem>
                                        <MenuItem value={80}>80</MenuItem>
                                        <MenuItem value={90}>90</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel htmlFor="height">Height</InputLabel>
                                    <Select onChange={handleHeight} value={height} style={{width: 80}} id='height'>
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={30}>30</MenuItem>
                                        <MenuItem value={40}>40</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={60}>60</MenuItem>
                                        <MenuItem value={70}>70</MenuItem>
                                        <MenuItem value={80}>80</MenuItem>
                                        <MenuItem value={90}>90</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel htmlFor="algorithm">Algorithm</InputLabel>
                                    <Select onChange={handleAlgorithm} value={algorithm} style={{width: 120}} id='algorithm'>
                                        <MenuItem value={Algorithm.DFS}>{`Depth First Search (DFS)`}</MenuItem>
                                        <MenuItem value={Algorithm.BFS}>{`Breadth First Search (BFS)`}</MenuItem>
                                        <MenuItem value={Algorithm.MANHATTAN}>{`Heuristic: Manhattan Distance`}</MenuItem>
                                        <MenuItem value={Algorithm.ASTAR}>{`Heuristic: A*`}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Place Start'>
                                    <IconButton onClick={handleStart}><Start /></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Place Goal'>
                                    <IconButton onClick={handleGoal}><Goal /></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Add Walls'>
                                    <IconButton onClick={handleWall}><Wall /></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Remove Item'>
                                    <IconButton onClick={handleRemove}><Trash /></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Reset Maze'>
                                    <IconButton onClick={handleResetMaze}><Restart /></IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title='Clear All'>
                                    <IconButton onClick={handleReset}><Clear /></IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container direction='row' justify='center' alignItems='center'>
                            <Grid item>
                                <Tooltip title=''>
                                    <Button onClick={handleSolve} color='primary' variant='contained'>Solve</Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Grid container direction='column' justify='center' alignItems='center'>
                {grid.map((row, rowNum) => (
                    <Grid item>
                        <Grid container direction='row'>
                            {row.map((space, colNum) => (
                                <Paper 
                                    onClick={(e) => handleClick(e, rowNum, colNum)} 
                                    onDragEnter={(e) => handleClick(e, rowNum, colNum)} 
                                    square 
                                    className={ 
                                                space === GridType.WALL ? classes.wall : 
                                                space === GridType.GOAL ?  classes.goal : 
                                                space === GridType.START ? classes.start : 
                                                space === GridType.EXPLORED ? classes.explored : 
                                                space === GridType.PATH ? classes.path :
                                                space === GridType.DISCOVERED ? classes.goalBright : null
                                              } 
                                    style={squareSize} 
                                    elevation={
                                        space === GridType.WALL ? 20 : 
                                        space === GridType.GOAL ? 10 :
                                        space === GridType.START ? 20 :
                                        space === GridType.DISCOVERED ? 20 : 
                                        space === GridType.EXPLORED ? 8 : 
                                        space === GridType.NONE ? 0 : 2
                                    }
                                >
                                </Paper>
                            ))}
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </div>
    )
}

export default MazeGrid