import React, { useEffect, useState } from 'react'
import { Paper, Grid, Container, IconButton, Button, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'
import X from '@material-ui/icons/Close'
import O from '@material-ui/icons/RadioButtonUnchecked'
import Restart from '@material-ui/icons/Replay'

const Space = {
    EMPTY: ' ',
    X: 'X',
    O: 'O'
}

class Board {
    constructor(gridIn) {
        if (gridIn) {
            this.grid = gridIn
        }
        else {
            var grid = []
            for (var row = 0; row < 3; row++ ) {
                grid.push([])
                for (var col = 0; col < 3; col++) {
                    grid[row].push(Space.EMPTY)
                }
            }
            this.grid = grid
        }
    }
    play(row, col, value)
    {
        if (this.grid[row][col] === Space.EMPTY) {
            this.grid[row][col] = value
            return this.grid
        } 
        else {
            return null
        }
    }
    turn() {
        var numX = 0;
        var numO = 0;
        for (var row = 0; row < 3; row++) {
            for (var col = 0; col < 3; col++) {
                if (this.grid[row][col] === Space.X) {
                    numX++;
                }
                else if (this.grid[row][col] === Space.O) {
                    numO++;
                }
            }
        }
        if (numX === numO) {
            return Space.X
        }
        else {
            return Space.O
        }
    }

};

function minValue(state) {
    if (terminal(state)) {
        return utility(state)
    }
    var value = 2
    var actions = actions1(state)

    for (var i = 0; i < actions.length; i++) {
        value = Math.min(value, maxValue(result(actions[i], deepCopy(state), Space.O)))
    } 
    return value
}

function maxValue(state) {
    if (terminal(state)) {
        return utility(state)
    }
    var value = -2
    var actions = actions1(state)
    for (var i = 0; i < actions.length; i++) {
        value = Math.max(value, minValue(result(actions[i], deepCopy(state), Space.X)))
    }
    return value
}

// returns all legal moves
function actions1(state) {
    var actions = []
    for (var row = 0; row < 3; row++) {
        for (var col = 0; col < 3; col++) {
            if (state[row][col] === Space.EMPTY) {
                actions.push( {row, col } )
            }
        }
    }
    return actions
}
// return board after action is taken
function result(action, state, value) {
    state[action.row][action.col] = value
    return state
}

function full(state) {
    for (var row = 0; row < 3; row++) {
        for (var col = 0; col < 3; col++) {
            if (state[row][col] === Space.EMPTY) {
                return false
            }
        }
    }
    return true
}
// checks if a state is in terminal state
function terminal(state) {
    // Check if board is filled
    if (full(state)) {
        return true
    }
    // check for 3 in a row
    for (var i = 0; i < 3; i++) {
        if (state[i][0]  === state[i][1] && state[i][1] === state[i][2] && state[i][2] !== Space.EMPTY) {
            return true;
        }
        if (state[0][i]  === state[1][i] && state[1][i]  === state[2][i] && state[2][i] !== Space.EMPTY) {
            return true;
        }
    }
    if (state[0][0]  === state[1][1] && state[1][1] === state[2][2] && state[2][2] !== Space.EMPTY) {
        return true
    }
    if (state[2][0]  === state[1][1] && state[1][1] === state[0][2] && state[0][2] !== Space.EMPTY) {
        return true
    }
    return false
}
// Checks the value of the terminal state
// O wins: -1, Draw: 0, X wins: 1
function utility(state) {
    for (var i = 0; i < 3; i++) {
        if (state[i][0]  === state[i][1] && state[i][1] === state[i][2] && state[i][2] !== Space.EMPTY) {
            if (state[i][0] === Space.X) {
                return 1
            } 
            else {
                return -1
            }
        }
        if (state[0][i]  === state[1][i] && state[1][i]  === state[2][i] && state[2][i] !== Space.EMPTY) {
            if (state[0][i] === Space.X) {
                return 1
            } 
            else {
                return -1
            }
        }
    }
    if (state[0][0]  === state[1][1] && state[1][1] === state[2][2] && state[2][2] !== Space.EMPTY) {
        if (state[0][0] === Space.X) {
            return 1
        } 
        else {
            return -1
        }
    }
    if (state[2][0]  === state[1][1] && state[1][1] === state[0][2] && state[0][2] !== Space.EMPTY) {
        if (state[2][0] === Space.X) {
            return 1
        } 
        else {
            return -1
        }
    }
    return 0
}

function deepCopy(grid) {
    var copy = []
    for (var row = 0; row < grid.length; row++) {
        copy.push([])
        for (var col = 0; col < grid[row].length; col++) {
            copy[row][col] = grid[row][col]
        }
    }
    return copy
}

function getMove(state) {
    var actions = actions1(state)
    var move = {
        value: 2,
        action: null,
    }
    var tempVal = 2
    for (var i = 0; i < actions.length; i++) {
        tempVal = maxValue(result(actions[i], deepCopy(state), Space.O))
        if (tempVal < move.value) {
            move.value = tempVal
            move.action = actions[i]
        }
    }
    return move
}


const useStyles = makeStyles(theme => ({
    centerText: {
        textAlign: 'center',
    },
    square: {
        width: 200,
        height: 200,
    },
    icon: {
        fontSize: 100,
    }
})) 

function TicTacToe(props) {
    const classes = useStyles()
    const [board, setBoard] = useState(new Board())
    const [turn, setTurn] = useState(Space.X)

    const handleClick = (location) => {
        console.log(board)
        var copy = deepCopy(board.grid)
        
        if (turn === Space.X) {  // == space.x
            var newGrid = board.play(location.row, location.col, Space.X) //Space.X
            if (newGrid) {
                setBoard(new Board(newGrid))
                setTurn(board.turn())
            }
            if (terminal(board.grid)) {
                console.log('terminal score', utility(board.grid))
            }
        }
        
    }

    const handleMove = (e) => {
        e.preventDefault()
        console.log(getMove(deepCopy(board.grid))) 
    } 

    const handleRestart = () => {
        setBoard(new Board())
        setTurn(Space.X)
    }

    useEffect(() => {
        if (turn === Space.O) {
            if (!terminal(deepCopy(board.grid))) {
                const move = getMove(deepCopy(board.grid))
                var newGrid = board.play(move.action.row, move.action.col, Space.O)
                if (newGrid) {
                    setBoard(new Board(newGrid))
                    setTurn(Space.X)
                }
            }
        }
    }, [board]) 

    return (
        <div style={{backgroundColor: grey[500], minHeight: '100vh', paddingTop: 50}}>
            <h1 className={classes.centerText}>Tic tac toe</h1>
            <Grid container direction='row' justify='center' alignItems='center' >
                <Grid item>
                    <Tooltip title="Restart Game" >
                        <IconButton onClick={handleRestart}>
                            <Restart />
                        </IconButton>
                    </Tooltip>
                </Grid>
                {/*
                <Grid item>
                        <Button onClick={handleMove}>
                            Get Move
                        </Button>
                </Grid>
                */}
            </Grid>
            <Grid container direction='column' justify='center' alignItems='center' >
                <Grid item>
                    <Grid container direction='row' justify='center' alignItems='center' >
                        <Grid item xs={16}>
                            <Paper id='0' onClick={() => handleClick({row: 0, col: 0})} className={classes.square} style={{borderRight: '4px solid black', borderBottom: '4px solid black'}}>
                                <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[0][0] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[0][0] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={16}>
                            <Paper id='1' onClick={() => handleClick({row: 0, col: 1})} className={classes.square} style={{borderRight: '4px solid black', borderBottom: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[0][1] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[0][1] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={16}>
                            <Paper id='2' onClick={() => handleClick({row: 0, col: 2})} className={classes.square} style={{borderBottom: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[0][2] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[0][2] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justify='center' alignItems='center' >
                        <Grid item xs={16}>
                            <Paper id='3' onClick={() => handleClick({row: 1, col: 0})} className={classes.square} style={{borderRight: '4px solid black', borderBottom: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[1][0] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[1][0] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={16}>
                            <Paper id='4' onClick={() => handleClick({row: 1, col: 1})} className={classes.square} style={{borderRight: '4px solid black', borderBottom: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[1][1] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[1][1] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={16}>
                            <Paper id='5' onClick={() => handleClick({row: 1, col: 2})} className={classes.square} style={{borderBottom: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[1][2] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[1][2] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container direction='row' justify='center' alignItems='center' >
                        <Grid item xs={16}>
                            <Paper id='6' onClick={() => handleClick({row: 2, col: 0})} className={classes.square} style={{borderRight: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[2][0] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[2][0] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={16}>
                            <Paper id='7' onClick={() => handleClick({row: 2, col: 1})} className={classes.square} style={{borderRight: '4px solid black'}}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[2][1] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[2][1] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                        <Grid item xs={16}>
                            <Paper id='8' onClick={() => handleClick({row: 2, col: 2})} className={classes.square}>
                            <Grid container direction='row' justify='center' alignItems='center' style={{height: '100%'}}>
                                    {
                                        board.grid[2][2] === Space.X ? <X className={classes.icon}/> :
                                        board.grid[2][2] === Space.O ? <O className={classes.icon}/> : null
                                    }
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}

export default TicTacToe