import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Typography, Button, IconButton, Container} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { withRouter } from 'react-router'

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
  }));

function Navigation({ history }) {
    const classes = useStyles()

    const handleMaze = (e) => {
        e.preventDefault()
        history.push('/maze')
    }

    const handleTicTac = (e) => {
        e.preventDefault()
        history.push('/tic-tac-toe')
    }

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Container maxWidth='md'>
                    <Toolbar>
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Interactive AI Lab
                    </Typography>
                    <Button color="inherit" onClick={handleMaze}>Maze</Button>
                    <Button color="inherit" onClick={handleTicTac}>Tic-Tac-Toe</Button>
                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    )
}

export default withRouter(Navigation)