import React, { useState } from 'react'
import MazeGrid from './MazeGrid'
import grey from '@material-ui/core/colors/grey'

function Maze(props) {

    const [width, setWidth] = useState(10)
    const [height, setHeight] = useState(10)


    return (
        <div style={{backgroundColor: grey[500], minHeight: '100vh'}}>
            <MazeGrid width={width} height={height} setWidth={setWidth} setHeight={setHeight}/>
        </div>
    )
}

export default Maze